import React from 'react'
import 'swiper/css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Api from '../Services/EcomApi';
import { Link } from 'react-router-dom';

class Products extends React.Component {


    componentDidMount = async()=>{
        await this.getCategories();
        await window.scroll(0, 0);
        this.setState({'loading' : false});
    }


    componentDidUpdate = async(prevProps, prevState)=>{
        if(prevProps.latitude != this.state.latitude || prevProps.longitude != this.state.longitude){
            await this.setState({'latitude' : prevProps.latitude, 'longitude' : prevProps.longitude}, ()=>{
                this.getProducts();
            });  
        }
    }



    constructor(props){
        super(props);
        this.state={
            'categories': [],
            'products': [],
            'category': "",
            'sub_category': "",
            'selected_sort': 0,
            'cart' : [],
            'show_search' : false,
            'searched_text' : "",
            "searched_products" : [],
            "loading" : false,
            "latitude" : "",
            "longitude" : ""
        }
        
    }


    getCategories = async()=>{
        var response = await Api.GetRequest({}, 'categories');
        if(response.status == 200){
            await this.setState({'categories' : response.categories});        
        }
        else{
            toast.error("Something went wrong. Please try again later.")
        }
    }



    getProducts = async () => {
        this.setState({'loading' : true, products : []});
        var params = {
            'sub_category_id': this.state.sub_category,
            'category_id': this.state.category,
            'sort_by' : this.state.selected_sort,
            'latitude' : this.state.latitude,
            'longitude' : this.state.longitude
        }
        var response = await Api.GetRequest(params, 'products');
        if (response.status == 200) {
            await this.setState({'products': response.products});
        }
        this.setState({'loading' : false})
    }


    resetField = async()=>{
        this.formRef.reset();
        this.setState({
            'products' : [],
            'categories': [],
            'selected_sort': 0,
            'show_filters' : false,
            'cart' : [],
            'show_search' : false,
            'searched_text' : "",
            "searched_products" : [],
        }, ()=>{
            this.applyFilters()
        });
    }


    
    setSorting = async(value)=>{
        console.log(value)
        this.setState({'selected_sort' : value},
        ()=>{
            this.applyFilters();
        }   
        );        
    }


    showProductDetails = async(product_id)=>{
        this.props.navigate('/shop/product/'+product_id);
    }

   

    applyFilters = async()=>{
        this.getProducts();
    }



    render() {
        return (
            <>
                <div class="tf-page-title">
                    <div class="container-full">
                        <div class="heading text-center">Shop</div>
                        {/* <p class="text-center text-2 text_black-2 mt_5">Shop through our latest selection of Fashion</p>  */}
                    </div>
                </div>

                <section class="flat-spacing-2">
                    <div class="container">
                        <div class="tf-shop-control grid-3 justify-content-between w-100 align-items-center">
                            {/* <div class="tf-control-filter">
                                <a href="#filterShop" data-bs-toggle="offcanvas" aria-controls="offcanvasLeft" class="tf-btn-filter"><span class="icon icon-filter"></span><span class="text">Filter</span></a>
                            </div>
                            
                            <div class="tf-control-sorting d-flex justify-content-end">
                                <div class="tf-dropdown-sort" data-bs-toggle="dropdown">
                                    <div class="btn-select">
                                        <span class="text-sort-value">Featured</span>
                                        <span class="icon icon-arrow-down"></span>
                                    </div>
                                    <div class="dropdown-menu">
                                        <div class="select-item active">
                                            <span class="text-value-item">Featured</span>
                                        </div>
                                        <div class="select-item">
                                            <span class="text-value-item">Best selling</span>
                                        </div>
                                        <div class="select-item">
                                            <span class="text-value-item">Alphabetically, A-Z</span>
                                        </div>
                                        <div class="select-item">
                                            <span class="text-value-item">Alphabetically, Z-A</span>
                                        </div>
                                        <div class="select-item">
                                            <span class="text-value-item">Price, low to high</span>
                                        </div>
                                        <div class="select-item">
                                            <span class="text-value-item">Price, high to low</span>
                                        </div>
                                        <div class="select-item">
                                            <span class="text-value-item">Date, old to new</span>
                                        </div>
                                        <div class="select-item">
                                            <span class="text-value-item">Date, new to old</span>
                                        </div>
                                    </div>
                                </div>
                                
                            </div> */}
                        </div>
                        <div class="wrapper-control-shop">
                            <div class="meta-filter-shop"></div>
                            <div class="grid-layout wrapper-shop" data-grid="grid-4">

                                {this.state.products.map((product, index)=>{
                                    return(
                                        <>
                                            <div class="card-product px-2 style-4 pb-2" style={{'border' : '1px solid #e1e1e1', borderRadius : '10px'}}>
                                                <div class="card-product-wrapper">
                                                    <Link to={`/shop/product/${product.id}`} class="product-img">
                                                        <img class="lazyload img-product" data-src={process.env.REACT_APP_MEDIA_URL + product.image} src={process.env.REACT_APP_MEDIA_URL + product.image} alt="image-product"/>
                                                        <img class="lazyload img-hover" data-src={process.env.REACT_APP_MEDIA_URL + product.image} src={process.env.REACT_APP_MEDIA_URL + product.image} alt="image-product"/>
                                                    </Link>
                                                    
                                                    <Link to={`/shop/product/${product.id}`} data-bs-toggle="modal" class="btn-quick-add quick-add">View</Link>
                                                </div>
                                                <div class="card-product-info">
                                                    <Link to={`/shop/product/${product.id}`} class="title link">{product.name}</Link>
                                                    <span class="price">
                                                        {product.discount == 0 &&
                                                            <>
                                                                ₹ {(product.price - (product.price * product.discount)/100).toFixed(0)}
                                                            </>
                                                        }
                                                        {product.discount > 0 &&
                                                            <>
                                                                <del>₹ {product.price}</del>
                                                                <span className="bg-success mx-2 px-2" style={{'color' : '#FFFFFF',  borderRadius : 50}}>{product.discount} % OFF</span>
                                                                <span>₹ {(product.price - (product.price * product.discount)/100).toFixed(0)}</span>
                                                            </>
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })}

                            </div>
                        </div>
                    </div>
                </section>
            </>        
        )
    }
}

export default Products;


