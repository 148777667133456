import React from 'react'


class Footer extends React.Component {


    constructor() {
        super();
        this.state = {
        };
    }





    render() {
        return (
            <>
               <footer id="footer" class="footer background-black">
                    <div class="footer-wrap wow fadeIn" data-wow-delay="0s">
                        <div class="footer-body">
                            <div class="container">
                                <div class="row">
                                    <div class="col-xl-3 col-md-6 col-12">
                                        <div class="footer-infor">
                                            <div class="footer-logo">
                                                <a href="index-2.html">
                                                    <img src="images/logo/logo-white.svg" alt=""/>
                                                </a>
                                            </div>
                                            <ul>
                                                <li>
                                                    <p style={{'textAlign' : 'left'}}>Address: 2nd Floor, Ravisankar Arcade, behind Care Hospital line, Jayabheri Pine Valley, Gachibowli, Hyderabad, Telangana 500032,<br/> India</p>
                                                </li>
                                                <li>
                                                    <p style={{'textAlign' : 'left'}}>Email: <a href="#">contact@sportitor.com</a></p>
                                                </li>
                                                <li>
                                                    <p style={{'textAlign' : 'left'}}>Phone: <a href="#">9700509888</a></p>
                                                </li>
                                            </ul>
                                            <a href="contact-1.html" class="tf-btn btn-line">Get direction<i class="icon icon-arrow1-top-left"></i></a>
                                            <ul class="tf-social-icon d-flex gap-10 style-white">
                                                <li><a href="#" class="box-icon w_34 round social-facebook social-line"><i class="icon fs-14 icon-fb"></i></a></li>
                                                <li><a href="#" class="box-icon w_34 round social-twiter social-line"><i class="icon fs-12 icon-Icon-x"></i></a></li>
                                                <li><a href="#" class="box-icon w_34 round social-instagram social-line"><i class="icon fs-14 icon-instagram"></i></a></li>
                                                <li><a href="#" class="box-icon w_34 round social-tiktok social-line"><i class="icon fs-14 icon-tiktok"></i></a></li>
                                                <li><a href="#" class="box-icon w_34 round social-pinterest social-line"><i class="icon fs-14 icon-pinterest-1"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-md-6 col-12 footer-col-block">
                                        <div class="footer-heading footer-heading-desktop">
                                            <h6>Help</h6>
                                        </div>
                                        <div class="footer-heading footer-heading-moblie">
                                            <h6>Help</h6>
                                        </div>
                                        <ul class="footer-menu-list tf-collapse-content">
                                            <li>
                                                <a href="privacy-policy.html" class="footer-menu_item">Privacy Policy</a>
                                            </li>
                                            <li> 
                                                <a href="delivery-return.html" class="footer-menu_item">  Returns + Exchanges </a>
                                            </li>
                                            <li> 
                                                <a href="shipping-delivery.html" class="footer-menu_item">Shipping</a>
                                            </li>
                                            <li> 
                                                <a href="terms-conditions.html" class="footer-menu_item">Terms &amp; Conditions</a>
                                            </li>
                                            <li> 
                                                <a href="faq-1.html" class="footer-menu_item">FAQ’s</a>
                                            </li>
                                            <li> 
                                                <a href="compare.html" class="footer-menu_item">Compare</a>
                                            </li>
                                            <li> 
                                                <a href="wishlist.html" class="footer-menu_item">My Wishlist</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-xl-3 col-md-6 col-12 footer-col-block">
                                        <div class="footer-heading footer-heading-desktop">
                                            <h6>About us</h6>
                                        </div>
                                        <div class="footer-heading footer-heading-moblie">
                                            <h6>About us</h6>
                                        </div>
                                        <ul class="footer-menu-list tf-collapse-content">
                                            <li>
                                                <a href="about-us.html" class="footer-menu_item">Our Story</a>
                                            </li>
                                            <li> 
                                                <a href="our-store.html" class="footer-menu_item">Visit Our Store</a>
                                            </li>
                                            <li> 
                                                <a href="contact-1.html" class="footer-menu_item">Contact Us</a>
                                            </li>
                                            <li> 
                                                <a href="login.html" class="footer-menu_item">Account</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-xl-3 col-md-6 col-12">
                                        <div class="footer-newsletter footer-col-block">
                                            <div class="footer-heading footer-heading-desktop">
                                                <h6>Sign Up for Email</h6>
                                            </div>
                                            <div class="footer-heading footer-heading-moblie">
                                                <h6>Sign Up for Email</h6>
                                            </div>
                                            <div class="tf-collapse-content">
                                                <div class="footer-menu_item">Sign up to get first dibs on new arrivals, sales, exclusive content, events and more!</div>
                                                <form class="form-newsletter" id="subscribe-form" action="#" method="post" accept-charset="utf-8" data-mailchimp="true">
                                                    <div id="subscribe-content">
                                                        <fieldset class="email">
                                                            <input type="email" name="email-form" id="subscribe-email" placeholder="Enter your email...." tabindex="0" aria-required="true"/>
                                                        </fieldset>
                                                        <div class="button-submit">
                                                            <button id="subscribe-button" class="tf-btn btn-sm radius-3 btn-fill btn-icon animate-hover-btn" type="button">Subscribe<i class="icon icon-arrow1-top-left"></i></button>
                                                        </div>
                                                    </div>
                                                    <div id="subscribe-msg"></div>
                                                </form>
                                                <div class="tf-cur flex-column">
                                                    <div class="footer-menu_item" style={{'color' : '#FFFFFF', fontWeight : 'bold', 'fontSize' : '17px'}}>Download our app from</div>
                                                    <div className='justify-content-center d-flex align-items-center flex-row'>
                                                        <div className='mx-1'>
                                                            <img src="assets/images/app_store.png"/>
                                                        </div>
                                                        <div className='mx-1'>
                                                            <img src="assets/images/google_play_store.png"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="footer-bottom">
                            <div class="container">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="footer-bottom-wrap d-flex gap-20 flex-wrap justify-content-between align-items-center">
                                            <div class="footer-menu_item">© 2024 SPORTITOR. All Rights Reserved</div>
                                            <div class="tf-payment">
                                                <img src="images/payments/visa.png" alt=""/>
                                                <img src="images/payments/img-1.png" alt=""/>
                                                <img src="images/payments/img-2.png" alt=""/>
                                                <img src="images/payments/img-3.png" alt=""/>
                                                <img src="images/payments/img-4.png" alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </>        
        )
    }
}

export default Footer;