import React from 'react'
import 'swiper/css';
import Api from '../Services/EcomApi';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Offcanvas from "react-bootstrap/Offcanvas";
import Button  from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import withRouter from '../Services/WithRouter';
import Login from '../Auth/login';

class Cart extends React.Component {


    componentDidMount = async()=>{
        window.scroll(0,0);
        await this.verifyUser();
    }



    constructor(props){
        super(props);
        this.state = {
            'cart' : [],
            'total_amount' : 0,
            'total_discount' : 0,
            'total_price' : 0,
            'show_login' : false,
            'stock_error' : false,

            'show_coupons' : false,
            'coupons' : [],
            
            'refer_code' : "",
            'coupon' : {},
            'discount_type' : "",
            
            'referral_discount' : 0,
            'coupon_discount' : 0,
            'coupon_code' : "",
            'coupon_error' : ""
        }
    }



    getCart = async()=>{
        var response = await Api.GetRequest({}, 'cart');
        if(response.status == 200){
            this.setState({
                'cart' : response.cart,
                'total_amount' : response.total_amount,
                'total_discount' : response.total_discount,
                'total_price' : response.total_price,
            },()=>{
                this.calculateOtherDiscount();
            });
            
        }
        else{
            toast.error("Something went wrong. Please try again later.");
        }
    }


    getCoupons = async()=>{
        var response = await Api.GetRequest({}, 'coupons');
        if(response.status == 200){
            this.setState({'coupons' : response.coupons});
        }
        else{
            toast.error("Something went wrong to fetch the coupons. Please try again later");
        }
        
    }


    removeProduct = async(cart_id)=>{
        var data = {
            'cart_id' : cart_id
        }
        
        var response = await Api.PostRequest(data, 'delete-from-cart');
        if(response.status == 200){
            toast.success("Prdouct removed from your cart successfully.")
            this.getCart();
            this.props.fetchCart()
        }
        else{
            toast.error("Something went wrong. Please try again later.");
        }
    }
    

    verifyUser = async()=>{
        var response = await Api.GetRequest({}, 'verify-user');
        if(response.status == 401){
            this.setState({'show_login' : true});
        }   
        else{
            await this.getCart();
            await this.getCoupons();
        } 
    }


    handleLogin = async(status)=>{
        this.setState({'show_login' : false});
        this.props.setUserLogIn(true);
        this.getCart();
        await this.getCoupons();
        this.props.fetchCart();
    }



    changeQuantity = async(status, cart_id)=>{
        var data ={
            'status' : status,
            'cart_id' : cart_id
        }
        var response = await Api.PostRequest(data, 'change-quantity');
        if(response.status == 200){
            this.getCart()
        }
        else{
            toast.error(response.message);
        }
    }


    calculateOtherDiscount = async()=>{
        if(this.state.discount_type == 1){
            var referral_discount = await (this.state.total_price - this.state.total_discount) * this.state.refer_code_discount / 100;
            this.setState({'referral_discount' : referral_discount});
        }
        if(this.state.discount_type == 2){
            var coupon_discount = await (this.state.total_price - this.state.total_discount) * this.state.coupon.discount / 100;
            this.setState({'coupon_discount' : coupon_discount});
        }
    }


    setCoupon = async(coupon)=>{
        await this.setState({
            'coupon' : coupon, 
            'discount_type' : 2,
            'refer_code' : "",
            'referral_discount' : 0,
            'show_coupons' : false,
            'coupon_error' : ""
        });
        await this.calculateOtherDiscount();

    }


    removeCouponCode = async()=>{
        await this.setState({'coupon' : {}, discount_type : "", 'refer_code' : "", 'refer_code_discount' : "", 'referral_discount' : 0, 'coupon_discount' : 0});
        await this.calculateOtherDiscount();
    }


    applyReferCode = async()=>{
        if(this.state.refer_code.length > 0){
            var data = {
                'refer_code': this.state.refer_code
            }
            var response = await Api.PostRequest(data, 'apply-refer-code');
            if(response.status == 200){
                toast.success("Referral code applied successfully.");
                await this.setState({'coupon' : {}, discount_type : 1, 'refer_code_discount' : response.referral_discount,'coupon_discount' : 0}, ()=>{
                    this.calculateOtherDiscount();
                });
            }
            else{
                await toast.error(response.message);
                this.setState({'coupon' : {}, discount_type : "", 'refer_code' : "", 'refer_code_discount' : "", 'referral_discount' : 0, 'coupon_discount' : 0});
            }
        }
    }


    removeReferCode = async()=>{
        await this.setState({'coupon' : {}, discount_type : "", 'refer_code' : "", 'refer_code_discount' : "", 'referral_discount' : 0, 'coupon_discount' : 0});
        await this.calculateOtherDiscount();
    }



    checkStock = async() =>{
        var response = await Api.GetRequest({}, 'check-stock');
        if(response.status == 200){

            var coupon_id = "";
            var refer_code = "";
            var coupon_discount = "";
            var referral_discount = "";

            if(this.state.discount_type == 1){
                coupon_id = "";
                refer_code = this.state.refer_code;
                coupon_discount = "";
                referral_discount = this.state.referral_discount;
            }
            if(this.state.discount_type == 2){
                refer_code = "";
                referral_discount = "";
                if(Object.keys(this.state.coupon).length > 0){
                    coupon_id = this.state.coupon.id;
                    coupon_discount = this.state.coupon_discount;
                }
            }
           
            this.props.navigate("/shop/checkout/",  {state : {'coupon_id' : coupon_id, 'coupon_discount' : coupon_discount, 'refer_code' : refer_code, 'referral_discount' : referral_discount, 'discount_type' : this.state.discount_type}});
        }
        else if(response.status == 406){
            this.setState({'stock_error' : true});
        }
        else{
            await toast.error(response.message);
        }
    }


    checkCouponCode = async()=>{
        var data = {
            'coupon_code' : this.state.coupon_code.trim()
        }
        if(data.coupon_code.length > 0){
            var response = await Api.PostRequest(data, 'coupon');
            if(response.status == 200){
                await this.setState({
                    'coupon' : response.coupon, 
                    'discount_type' : 2,
                    'refer_code' : "",
                    'referral_discount' : 0,
                    'show_coupons' : false
                }, ()=>{
                     this.calculateOtherDiscount();
                });
                
            }
            else{
                this.setState({'coupon_error' : "Please enter valid coupon code."})
            }
        }
        else{
            this.setState({'coupon_error' : "Please enter valid coupon code."})
        }
    }



    handleLogin = async()=>{
        this.setState({'show_login' : false});
        this.props.setUserLogIn(true);
        this.getCart();
        this.props.fetchCart();
    }
 
 
    closeLogin = async()=>{
        this.setState({'show_login' : false});
    }



    render() {
        return (
            <>
                <Login show_login={this.state.show_login} handleLogin={this.handleLogin}/>
                <Offcanvas
                    className="coupon-canvas"
                    style={{ zIndex: "10000", width: "35vw" }}
                    placement={"end"}
                    show={this.state.show_coupons}
                    onHide={() => {
                        this.setState({'show_coupons' : false})
                    }}
                >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title className='mb-0'>
                                <h6 className='mb-0'>Coupons</h6>
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <div className='justify-content-center d-flex flex-row align-items-center'>
                                <div className='justify-content-start d-flex flex-column align-items-baseline w-100'>
                                    <input className="form-control" value={this.state.coupon_code} onChange={(event)=>{this.setState({'coupon_code' : event.target.value})}}  type="text" name="coupon_code" placeholder="Enter coupon code"/>
                                    <small style={{'color' : 'red'}}>{this.state.coupon_error}</small>
                                </div>
                                
                                <a href="javascript:;" class="tf-btn btn-fill animate-hover-btn radius-3 justify-content-center" onClick={()=>{this.checkCouponCode()}}>Apply</a>
                                
                            </div>
                            {this.state.coupons.map((coupon, index)=>{
                                return(

                                    <div className='card my-5' style={{'border' : '1px dashed #000000', cursor : "pointer"}}>
                                        <div className='w-100 card-header p-2 justify-content-between d-flex align-items-center flex-row'>
                                            <div className='flex-column d-flex'>
                                                <b onClick={()=>{this.setState({'show_coupon' : index})}}>{coupon.code}</b>
                                                <small>{coupon.description}</small>
                                            </div>
                                            <a href="javascript:;" class="tf-btn btn-fill animate-hover-btn radius-3 justify-content-center" onClick={()=>{this.setCoupon(coupon)}}>Apply</a>
                                        </div>
                                    </div>
                                )
                            })}
                        </Offcanvas.Body>
                </Offcanvas>

                <div class="tf-page-title">
                    <div class="container-full">
                        <div class="heading text-center">Shopping Cart</div>
                    </div>
                </div>

                <section class="flat-spacing-11">
                    <div class="container">
                        {this.state.cart.length == 0 &&
                            <div class="tf-page-cart text-center mt_140 mb_200">
                                <h5 class="mb_24">Your cart is empty</h5>
                                <p class="mb_24">You may check out all the available products and buy some in the shop</p>
                                <Link to={"/shop/products"} class="tf-btn btn-sm radius-3 btn-fill btn-icon animate-hover-btn">Return to shop<i class="icon icon-arrow1-top-left"></i></Link>
                            </div> 
                        }
                        {this.state.cart.length > 0 &&
                            <>
                                <div class="tf-cart-countdown">
                                    {/* <div class="title-left">
                                        <svg class="d-inline-block" xmlns="http://www.w3.org/2000/svg" width="16" height="24" viewBox="0 0 16 24" fill="rgb(219 18 21)">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0899 24C11.3119 22.1928 11.4245 20.2409 10.4277 18.1443C10.1505 19.2691 9.64344 19.9518 8.90645 20.1924C9.59084 18.2379 9.01896 16.1263 7.19079 13.8576C7.15133 16.2007 6.58824 17.9076 5.50148 18.9782C4.00436 20.4517 4.02197 22.1146 5.55428 23.9669C-0.806588 20.5819 -1.70399 16.0418 2.86196 10.347C3.14516 11.7228 3.83141 12.5674 4.92082 12.8809C3.73335 7.84186 4.98274 3.54821 8.66895 0C8.6916 7.87426 11.1062 8.57414 14.1592 12.089C17.4554 16.3071 15.5184 21.1748 10.0899 24Z"></path>
                                        </svg>
                                        <p>These products are limited</p>
                                    </div> */}
                                </div>
                                <div class="tf-page-cart-wrap">
                                    <div class="tf-page-cart-item">
                                        <form>
                                            <table class="tf-table-page-cart">
                                                <thead>
                                                    <tr>
                                                        <th>Product</th>
                                                        <th>Price</th>
                                                        <th>Color</th>
                                                        <th>Size</th>
                                                        <th>Quantity</th>
                                                        <th class="text-center">Discount</th>
                                                        <th class="text-center">Subtotal</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.cart.map((product, index)=>{
                                                        return(
                                                            <tr class="tf-cart-item file-delete">
                                                                <td class="tf-cart-item_product">
                                                                    <a href="product-detail.html" class="img-box">
                                                                        <img src={process.env.REACT_APP_MEDIA_URL +'/'+ product.image } alt="img-product"/>
                                                                    </a>
                                                                    <div class="cart-info">
                                                                        <a href="product-detail.html" class="cart-title link">{product.name}</a>
                                                                        <span class="remove-cart link remove" onClick={()=>{this.removeProduct(product.id)}}>Remove</span>
                                                                    </div>
                                                                </td>
                                                                <td class="tf-cart-item_price" cart-data-title="Price">
                                                                    <div class="cart-price">{product.price}</div>
                                                                </td>
                                                                <td class="tf-cart-item_price" cart-data-title="Price">
                                                                    <div class="cart-price">{product.color}</div>
                                                                </td>
                                                                <td class="tf-cart-item_price" cart-data-title="Price">
                                                                    <div class="cart-price">{product.size}</div>
                                                                </td>
                                                                <td class="tf-cart-item_quantity" cart-data-title="Quantity">
                                                                    <div class="cart-quantity flex-column">
                                                                        <div class="wg-quantity">
                                                                            <span class="btn-quantity minus-btn" onClick={()=>{this.changeQuantity(0, product.id)}}>
                                                                                <svg class="d-inline-block" width="9" height="1" viewBox="0 0 9 1" fill="currentColor"><path d="M9 1H5.14286H3.85714H0V1.50201e-05H3.85714L5.14286 0L9 1.50201e-05V1Z"></path></svg>
                                                                            </span>
                                                                            <input type="text" name="number" value={product.quantity}/>
                                                                            <span class="btn-quantity plus-btn" onClick={()=>{this.changeQuantity(1, product.id)}}>
                                                                                <svg class="d-inline-block" width="9" height="9" viewBox="0 0 9 9" fill="currentColor"><path d="M9 5.14286H5.14286V9H3.85714V5.14286H0V3.85714H3.85714V0H5.14286V3.85714H9V5.14286Z"></path></svg>
                                                                            </span>
                                                                        </div>
                                                                        {(product.quantity > product.max_quantity && product.max_quantity != 0) &&
                                                                            <small style={{'color' : 'red', marginTop:'10px', 'textAlign' : 'center'}}>Only {product.max_quantity} left</small>
                                                                        }
                                                                    
                                                                        {(product.max_quantity == 0) &&
                                                                            <small style={{'color' : 'red', marginTop:'10px', 'textAlign' : 'center'}}>Out of stock</small>
                                                                        }
                                                                    </div>
                                                                </td>
                                                                <td class="tf-cart-item_total" cart-data-title="Total">
                                                                    <div class="cart-total">{product.discount} %</div>
                                                                </td>
                                                                <td class="tf-cart-item_total" cart-data-title="Total">
                                                                    <div class="cart-total">₹ {(product.price - product.price * product.discount /100).toFixed(0)}</div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                            
                                        </form>
                                    </div>
                                    <div class="tf-page-cart-footer">
                                        <div class="tf-cart-footer-inner">
                                            <div class="tf-free-shipping-bar">
                                                <div class="tf-progress-bar">
                                                    <span style={{"width": "50%"}}>
                                                        <div class="progress-car">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="14" viewBox="0 0 21 14" fill="currentColor">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0.875C0 0.391751 0.391751 0 0.875 0H13.5625C14.0457 0 14.4375 0.391751 14.4375 0.875V3.0625H17.3125C17.5867 3.0625 17.845 3.19101 18.0104 3.40969L20.8229 7.12844C20.9378 7.2804 21 7.46572 21 7.65625V11.375C21 11.8582 20.6082 12.25 20.125 12.25H17.7881C17.4278 13.2695 16.4554 14 15.3125 14C14.1696 14 13.1972 13.2695 12.8369 12.25H7.72563C7.36527 13.2695 6.39293 14 5.25 14C4.10706 14 3.13473 13.2695 2.77437 12.25H0.875C0.391751 12.25 0 11.8582 0 11.375V0.875ZM2.77437 10.5C3.13473 9.48047 4.10706 8.75 5.25 8.75C6.39293 8.75 7.36527 9.48046 7.72563 10.5H12.6875V1.75H1.75V10.5H2.77437ZM14.4375 8.89937V4.8125H16.8772L19.25 7.94987V10.5H17.7881C17.4278 9.48046 16.4554 8.75 15.3125 8.75C15.0057 8.75 14.7112 8.80264 14.4375 8.89937ZM5.25 10.5C4.76676 10.5 4.375 10.8918 4.375 11.375C4.375 11.8582 4.76676 12.25 5.25 12.25C5.73323 12.25 6.125 11.8582 6.125 11.375C6.125 10.8918 5.73323 10.5 5.25 10.5ZM15.3125 10.5C14.8293 10.5 14.4375 10.8918 14.4375 11.375C14.4375 11.8582 14.8293 12.25 15.3125 12.25C15.7957 12.25 16.1875 11.8582 16.1875 11.375C16.1875 10.8918 15.7957 10.5 15.3125 10.5Z"></path>
                                                            </svg>
                                                        </div>
                                                    </span>
                                                </div>
                                                <div class="tf-progress-msg">
                                                    <span class="fw-6">Free Shipping</span>
                                                </div>
                                            </div>
                                            <div class="tf-page-cart-checkout">

                                                <div className='card'>
                                                    <div className='card-body'>
                                                        <div className="input-group my-2">
                                                            <label className='w-100' style={{'textAlign' : "left", fontWeight : "bold", 'width' : '100%'}}>Refer Code</label>
                                                            <div className='justify-content-center d-flex mt-2 flex-row align-items-center'>
                                                                <input disabled={this.state.discount_type == 1} value={this.state.refer_code} onChange={(event)=>{this.setState({'refer_code' : event.target.value})}} className="form-control" type="text" name="refer_code" placeholder="Enter Refer code"/>
                                                                {this.state.discount_type != 1 &&
                                                                    <a href="javascript:;" class="tf-btn btn-fill animate-hover-btn radius-3 justify-content-center" onClick={()=>{this.applyReferCode()}}>Apply</a>
                                                                }
                                                                {this.state.discount_type == 1 &&
                                                                    <a href="javascript:;" class="tf-btn btn-fill animate-hover-btn radius-3 justify-content-center" onClick={()=>{this.removeReferCode()}}>Remove</a>
                                                                }
                                                            </div>
                                                        </div>
                                                        
                                                        <>
                                                            <div className="justify-content-center d-flex align-items-center">
                                                                <hr style={{'width' : '40%'}}></hr>
                                                                <b>OR</b>
                                                                <hr style={{'width' : '40%'}}></hr>
                                                            </div>
                                                            <div className="input-group my-2">
                                                                <label className='w-100' style={{'textAlign' : "left", fontWeight : "bold", 'width' : '100%'}}>Coupon</label>
                                                                <div className='justify-content-center d-flex flex-row align-items-center mt-2'>
                                                                    <input disabled value={this.state.coupon.code ? this.state.coupon.code : ""} className="form-control" type="text" name="refer_code" placeholder="Select coupon code"/>
                                                                    {this.state.discount_type != 2 &&
                                                                        <a href="javascript:;" class="tf-btn btn-fill animate-hover-btn radius-3 justify-content-center" onClick={()=>{this.setState({'show_coupons' : true})}}>Select</a>
                                                                    }
                                                                    {this.state.discount_type == 2 &&
                                                                        <a href="javascript:;" class="tf-btn btn-fill animate-hover-btn radius-3 justify-content-center" onClick={()=>{this.removeCouponCode()}}>Remove</a>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                
                                                
                                                <div class="tf-cart-totals-discounts">
                                                    <table className='table bill-table table-bordered w-100'>
                                                        <tbody>
                                                            <tr>
                                                                <th style={{'textAlign' : 'left'}}>MRP Total</th>
                                                                <td style={{'textAlign' : 'right'}}>₹ {this.state.total_price.toFixed(0)}</td>
                                                            </tr>
                                                            <tr>
                                                                <th style={{'textAlign' : 'left'}}>Discount</th>
                                                                <td style={{'textAlign' : 'right'}}>-₹ {this.state.total_discount.toFixed(0)}</td>
                                                            </tr>
                                                            {this.state.discount_type == 1 &&
                                                                <tr>
                                                                    <th style={{'textAlign' : 'left'}}>Referral Discount</th>
                                                                    <td style={{'textAlign' : 'right'}}>-₹ {this.state.referral_discount.toFixed(0)}</td>
                                                                </tr>
                                                            }
                                                            {this.state.discount_type == 2 &&
                                                                <tr>
                                                                    <th style={{'textAlign' : 'left'}}>Coupon Discount</th>
                                                                    <td style={{'textAlign' : 'right'}}>-₹ {this.state.coupon_discount.toFixed(0)}</td>
                                                                </tr>
                                                            }
                                                            
                                                            <tr>
                                                                <th style={{'textAlign' : 'left'}}>To Pay</th>
                                                                <td style={{'textAlign' : 'right'}}>
                                                                    {this.state.total_discount > 0 &&
                                                                        <span style={{'fontWeight' : "bold", "textDecorationLine" : "line-through", 'marginRight' : '4%'}}>₹ {(this.state.total_price - this.state.referral_discount - this.state.coupon_discount).toFixed(0)}</span>
                                                                    }

                                                                    
                                                                    <span style={{'fontWeight' : "bold"}}>₹ {(this.state.total_price - this.state.total_discount - this.state.referral_discount - this.state.coupon_discount).toFixed(0)}</span>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                
                                                <div class="cart-checkout-btn">
                                                    <a href="javascript:;" onClick={()=>{this.checkStock()}} class="tf-btn w-100 btn-fill animate-hover-btn radius-3 justify-content-center">
                                                        <span>Check out</span>
                                                    </a>
                                                </div>
                                                <div class="tf-page-cart_imgtrust">
                                                    <p class="text-center fw-6">Guarantee Safe Checkout</p>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        
                    </div>
                </section>
                        
            </>        
        )
    }
}

export default withRouter(Cart);


